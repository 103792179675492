<template>
    <v-dialog :persistent="creatingInstance === true" v-model="createInstanceDialog" width="700" scrollable height="600">
        <template v-slot:activator="{ on }">
            <v-btn v-if="isTextActivator === false" text :outlined="outlinedButton" color="primary" v-on="on">
                <v-icon small>add</v-icon><span class="font-weight-bold caption">Add New Instance</span></v-btn
            >
            <v-hover v-else-if="isTextActivator === true" v-slot:default="{ hover }">
                <span
                    v-on="on"
                    :class="[hover ? 'primary--text' : 'secondary--text', 'font-weight-bold', 'caption']"
                    :style="hover ? 'cursor: pointer;' : ''"
                    >{{ activatorText }}</span
                >
            </v-hover>
        </template>
        <v-card flat>
            <v-card-title>
                <div style="width:100%" class="d-flex align-center justify-space-between primary--text">
                    <div>
                        <v-icon class="mr-1">add</v-icon>
                        New Instance Creation
                    </div>
                    <div>
                        <v-btn :disabled="creatingInstance === true" icon @click="createInstanceDialog = false"><v-icon>clear</v-icon></v-btn>
                    </div>
                </div></v-card-title
            >
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-text class="ma-0 pa-0">
                <div v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="pa-5">
                    <v-alert type="info">
                        <span
                            >If creating group instances, please
                            <a class="white--text" href="https://docs.nuvolos.cloud/education/instructor-topics/set-up-group-work" target="_blank"
                                >read our recommendation</a
                            >
                            for best-practices to handle groups.</span
                        >
                    </v-alert>
                </div>
                <v-stepper style="box-shadow:none; padding-bottom: 0;" v-model="currentStep" vertical>
                    <v-stepper-step :color="currentStep > 1 ? 'secondary' : 'primary'" :complete="currentStep > 1" step="1">Instance content</v-stepper-step>
                    <v-stepper-content step="1">
                        <div class="pa-3">
                            <v-radio-group class="ma-0 pa-0" v-model="instanceContent">
                                <v-radio label="Empty instance" :value="instanceContentOptions.EMPTY_INSTANCE"></v-radio>
                                <v-radio label="Create from snapshot" :value="instanceContentOptions.CREATE_FROM_SNAPSHOT"></v-radio>
                            </v-radio-group>
                            <div v-if="instanceContent === instanceContentOptions.EMPTY_INSTANCE">
                                <v-alert border="left" colored-border type="info">
                                    <div class="d-flex flex-column">
                                        <span class="font-weight-bold">Empty Instance Creation</span>
                                        The instance you will create will have no contents (files, tables, or applications). You can, however, share content
                                        with the instance anytime in the future.
                                    </div>
                                </v-alert>
                            </div>
                            <div v-else-if="instanceContent === instanceContentOptions.CREATE_FROM_SNAPSHOT">
                                <v-alert colored-border border="left" type="info" v-if="selectedSnapshot.length">
                                    <p>
                                        The instance you will create will contain all the contents (files, tables, and applications) of the snapshot
                                        <span class="font-weight-bold">{{ selectedSnapshotName }}</span
                                        >.
                                    </p>
                                </v-alert>
                                <div class="d-flex align-center">
                                    <v-icon class="mr-1" small>group</v-icon>
                                    <span class="subtitle-1 font-weight-bold">Instance</span>
                                </div>
                                <v-select
                                    :items="instanceList"
                                    v-model="selectedInstance"
                                    @change="getInstanceData(false, false)"
                                    item-text="state"
                                    label="Select Instance"
                                    persistent-hint
                                    hint="Select the instance to choose a snapshot from"
                                    return-object
                                    single-line
                                    dense
                                    style="width:350px"
                                    outlined
                                ></v-select>
                                <v-data-table
                                    v-model="selectedSnapshot"
                                    show-select
                                    :headers="headers"
                                    :items="snapshots"
                                    :items-per-page="-1"
                                    :custom-sort="customSort"
                                    :sort-desc="true"
                                    item-key="snid"
                                    :sort-by="['snapshot_timestamp']"
                                    single-select
                                    :loading="instanceFetching"
                                    v-if="selectedInstance && snapshots.length"
                                    loading-text="Fetching instance data... Please wait"
                                >
                                    <template v-slot:item.long_id="{ item }">{{ item.long_id }}</template>
                                    <template v-slot:item.snapshot_timestamp="{ item }">{{ item.snapshot_timestamp | dateTimeToHuman }}</template>
                                </v-data-table>
                                <v-alert text prominent v-else-if="selectedInstance && !snapshots.length" type="warning"
                                    >The selected instance does no contain snapshots.</v-alert
                                >
                            </div>
                        </div>

                        <v-btn
                            :disabled="!instanceContent || (instanceContent === instanceContentOptions.CREATE_FROM_SNAPSHOT && !selectedSnapshot.length)"
                            color="secondary"
                            @click="currentStep = 2"
                            >continue</v-btn
                        >
                    </v-stepper-content>

                    <v-stepper-step :color="currentStep > 2 ? 'secondary' : 'primary'" :complete="currentStep > 2" step="2">Create instances</v-stepper-step>

                    <v-stepper-content step="2">
                        <div>
                            <v-form ref="form" v-model="validSingleInstance">
                                <h4 class="primary--text">Name of the instance</h4>
                                <v-text-field
                                    label="Provide an instance name here"
                                    v-model="instanceName"
                                    :rules="[rules.nonEmpty]"
                                    required
                                    :disabled="creatingInstance"
                                >
                                </v-text-field>
                                <ShortIdInput :disabledEdit="creatingInstance" :longId="instanceName" @input="updateShortId($event.nextValue)"></ShortIdInput>
                                <h4 class="mt-4 primary--text">Description of the instance</h4>
                                <v-textarea
                                    v-model="instanceDescription"
                                    label="Provide a description of your instance"
                                    rows="3"
                                    auto-grow
                                    :rules="[rules.nonEmpty]"
                                    required
                                    :disabled="creatingInstance"
                                ></v-textarea>
                            </v-form>
                        </div>
                        <v-btn :disabled="creatingInstance" color="secondary" @click="currentStep = 1" text outlined>Back</v-btn>
                        <v-btn
                            color="secondary"
                            class="ml-1"
                            @click="addInstance"
                            :loading="creatingInstance"
                            :disabled="validSingleInstance === false || creatingInstance"
                        >
                            <v-icon small>add</v-icon>Add Instance
                        </v-btn>
                    </v-stepper-content>
                    <v-stepper-step :color="currentStep > 3 ? 'secondary' : 'primary'" :complete="currentStep > 3" step="3">Invite users</v-stepper-step>
                    <v-stepper-content step="3">
                        <v-alert text type="info" prominent>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">invite users</span>
                                Do you want to invite users to this instance?
                            </div>
                        </v-alert>
                        <div class="d-flex justify-end">
                            <v-btn @click="completeInstanceCreation(false)" color="primary" text>close</v-btn
                            ><v-btn @click="completeInstanceCreation(true)" color="secondary" text>invite</v-btn>
                        </div>
                    </v-stepper-content>
                </v-stepper>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div style="width:100%" class="d-flex flex-column align-end">
                    <v-alert :value="$data.error" color="error" icon="warning" class="mt-4" text>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">{{ errorContent }}</span>
                            <span>
                                For more information on instance creation issues, check the troubleshooting documentation
                                <v-btn
                                    class="font-weight-bold"
                                    small
                                    text
                                    color="error"
                                    href="https://docs.nuvolos.cloud/troubleshooting/authorization-issues/cannot-create-an-instance"
                                    target="_blank"
                                    >here</v-btn
                                >
                            </span>
                        </div>
                    </v-alert>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { sortDateArray, sortArray, isCurrentState } from '@/utils'
import { enumsData } from '@/mixins/enums'
const ShortIdInput = () => import('@/components/ShortIdInput')
function defaultData() {
    return {
        currentStep: 1,
        instanceName: '',
        instanceShortName: '',
        instanceDescription: '',
        instanceCreationMode: 'multipleInstances',
        instanceContent: '',
        error: false,
        emailsInput: '',
        errorContent: 'An error has occurred',
        rules: {
            nonEmpty: p => p.length > 0 || 'Please provide a value.'
        },
        creatingInstance: false,
        validSingleInstance: false,
        selectedSnapshot: [],
        selectedInstance: null,
        createInstanceDialog: false,
        instanceContentOptions: {
            EMPTY_INSTANCE: 'emptyInstance',
            CREATE_FROM_SNAPSHOT: 'instanceFromSnapshot'
        },
        headers: [
            {
                text: 'Snapshot Name',
                align: 'left',
                value: 'long_id',
                sortable: true
            },
            {
                text: 'Creation Time',
                align: 'left',
                value: 'snapshot_timestamp',
                sortable: true
            }
        ],
        instanceFetching: false,
        snapshots: []
    }
}

export default {
    name: 'AddInstanceDialog',
    mixins: [enumsData],
    props: {
        outlinedButton: {
            type: Boolean,
            default: false
        },
        isTextActivator: {
            type: Boolean,
            default: false
        },
        activatorText: {
            type: String,
            default: ''
        }
    },
    data() {
        return defaultData()
    },
    components: {
        ShortIdInput
    },
    methods: {
        customSort: function(items, index, isDesc) {
            if (items.length && index[0] === 'snapshot_timestamp' && !isDesc[0]) {
                return sortDateArray(items, 'snapshot_timestamp', 'ascending')
            } else if (items.length && index[0] === 'snapshot_timestamp' && isDesc[0]) {
                return sortDateArray(items, 'snapshot_timestamp', 'descending')
            } else if (items.length && !isDesc[0]) {
                return sortArray(items, 'long_id', 'ascending', true)
            } else if (items.length && isDesc[0]) {
                return sortArray(items, 'long_id', 'descending', true)
            }
        },
        updateShortId(newShortId) {
            this.$data.instanceShortName = newShortId
        },
        fetchInstances() {
            this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
        },
        completeInstanceCreation(invite) {
            if (invite === true) {
                this.$emit('inviteUsers', { instanceName: this.$data.instanceName })
            }
            this.fetchInstances()
            this.$data.createInstanceDialog = false
        },
        getInstanceData: function(instanceMetaData, snapshotMetaData) {
            this.$data.selectedSnapshot = []
            var iid
            if (instanceMetaData) {
                iid = instanceMetaData.iid
                this.$data.selectedInstance = instanceMetaData.long_id
            } else {
                iid = this.spaceInstances.filter(instance => instance.long_id === this.selectedInstance)[0].iid
            }
            this.$data.instanceFetching = true
            this.$axios.get(`/instances/${iid}/snapshots`).then(response => {
                const snapshots = response.data.filter(snapshot => isCurrentState(snapshot.short_id) === false && snapshot.lock_mode_name !== 'DELETING')
                this.$data.snapshots = sortArray(snapshots, 'snid', 'descending', false)
                if (snapshotMetaData) {
                    this.$data.selectedSnapshot = [snapshotMetaData]
                }
                this.$data.instanceFetching = false
            })
        },
        addInstance: function() {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Creating instance, please wait...',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            this.$data.error = false
            var postBody = {}
            var apiURL = ''
            if (this.$data.instanceContent === this.$data.instanceContentOptions.CREATE_FROM_SNAPSHOT && this.selectedSnapshot.length) {
                apiURL = '/spaces/' + this.$route.params.sid + '/instances/from_snapshot/' + this.selectedSnapshot[0].snid
            } else {
                apiURL = '/spaces/' + this.$route.params.sid + '/instances'
            }
            if (this.$data.instanceName && this.$data.instanceDescription) {
                postBody.description = this.$data.instanceDescription
                postBody.long_id = this.$data.instanceName
                postBody.short_id = this.$data.instanceShortName
            }
            if (Object.keys(postBody).length) {
                this.$data.creatingInstance = true
                this.$axios
                    .post(apiURL, postBody, { timeout: 300000 })
                    .then(response => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Instance created successfully!',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        this.$data.currentStep = 3
                        this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
                    })
                    .catch(error => {
                        this.$data.error = true
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.reason &&
                            error.response.data.reason[0].includes('duplicate key value violates unique constraint "uc_sid_short"')
                        ) {
                            this.$data.errorContent = 'Another instance uses the short name you chose for this instance, please choose another name'
                        } else if (
                            error.response &&
                            error.response.data &&
                            error.response.data.reason &&
                            error.response.data.reason[0].includes('duplicate key value violates unique constraint "uc_sid_long"')
                        ) {
                            this.$data.errorContent =
                                'An instance associated with ' +
                                error.response.data.reason[0]
                                    .match(/\d,.*/g)[0]
                                    .replace(' already exists.', '')
                                    .slice(3)
                                    .replace(')', '') +
                                ' exists already'
                        } else {
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: 'Failed to create the instance, please try again later.!',
                                snackBarTimeout: 10000,
                                snackBarIcon: 'error'
                            })
                        }
                    })
                    .finally(() => {
                        this.$data.creatingInstance = false
                    })
            }
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInstances']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        selectedSnapshotName() {
            const selected = this.selectedSnapshot
            if (selected.length) {
                return selected[0].long_id
            } else {
                return null
            }
        },
        instanceList() {
            const instances = this.spaceInstances.map(instance => instance.long_id)
            return instances
        }
    },
    watch: {
        createInstanceDialog: function(nextVal, preVal) {
            if (nextVal === false && preVal === true) {
                Object.assign(this.$data, defaultData())
            } else if (nextVal === true) {
                this.$emit('dialogIsOpen', { value: true })
            }
        }
    }
}
</script>
