var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: _vm.creatingInstance === true,
        width: "700",
        scrollable: "",
        height: "600"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.isTextActivator === false
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          text: "",
                          outlined: _vm.outlinedButton,
                          color: "primary"
                        }
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                      _c("span", { staticClass: "font-weight-bold caption" }, [
                        _vm._v("Add New Instance")
                      ])
                    ],
                    1
                  )
                : _vm.isTextActivator === true
                ? _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var hover = ref.hover
                            return [
                              _c(
                                "span",
                                _vm._g(
                                  {
                                    class: [
                                      hover
                                        ? "primary--text"
                                        : "secondary--text",
                                      "font-weight-bold",
                                      "caption"
                                    ],
                                    style: hover ? "cursor: pointer;" : ""
                                  },
                                  on
                                ),
                                [_vm._v(_vm._s(_vm.activatorText))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ]
          }
        }
      ]),
      model: {
        value: _vm.createInstanceDialog,
        callback: function($$v) {
          _vm.createInstanceDialog = $$v
        },
        expression: "createInstanceDialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-center justify-space-between primary--text",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "div",
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [_vm._v("add")]),
                    _vm._v(" New Instance Creation ")
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.creatingInstance === true,
                          icon: ""
                        },
                        on: {
                          click: function($event) {
                            _vm.createInstanceDialog = false
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("clear")])],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-0" },
            [
              _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
                ? _c(
                    "div",
                    { staticClass: "pa-5" },
                    [
                      _c("v-alert", { attrs: { type: "info" } }, [
                        _c("span", [
                          _vm._v("If creating group instances, please "),
                          _c(
                            "a",
                            {
                              staticClass: "white--text",
                              attrs: {
                                href:
                                  "https://docs.nuvolos.cloud/education/instructor-topics/set-up-group-work",
                                target: "_blank"
                              }
                            },
                            [_vm._v("read our recommendation")]
                          ),
                          _vm._v(" for best-practices to handle groups.")
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-stepper",
                {
                  staticStyle: { "box-shadow": "none", "padding-bottom": "0" },
                  attrs: { vertical: "" },
                  model: {
                    value: _vm.currentStep,
                    callback: function($$v) {
                      _vm.currentStep = $$v
                    },
                    expression: "currentStep"
                  }
                },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: _vm.currentStep > 1 ? "secondary" : "primary",
                        complete: _vm.currentStep > 1,
                        step: "1"
                      }
                    },
                    [_vm._v("Instance content")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "div",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "ma-0 pa-0",
                              model: {
                                value: _vm.instanceContent,
                                callback: function($$v) {
                                  _vm.instanceContent = $$v
                                },
                                expression: "instanceContent"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "Empty instance",
                                  value:
                                    _vm.instanceContentOptions.EMPTY_INSTANCE
                                }
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Create from snapshot",
                                  value:
                                    _vm.instanceContentOptions
                                      .CREATE_FROM_SNAPSHOT
                                }
                              })
                            ],
                            1
                          ),
                          _vm.instanceContent ===
                          _vm.instanceContentOptions.EMPTY_INSTANCE
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        border: "left",
                                        "colored-border": "",
                                        type: "info"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v("Empty Instance Creation")]
                                          ),
                                          _vm._v(
                                            " The instance you will create will have no contents (files, tables, or applications). You can, however, share content with the instance anytime in the future. "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm.instanceContent ===
                              _vm.instanceContentOptions.CREATE_FROM_SNAPSHOT
                            ? _c(
                                "div",
                                [
                                  _vm.selectedSnapshot.length
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            "colored-border": "",
                                            border: "left",
                                            type: "info"
                                          }
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              " The instance you will create will contain all the contents (files, tables, and applications) of the snapshot "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedSnapshotName
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(". ")
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { small: "" }
                                        },
                                        [_vm._v("group")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subtitle-1 font-weight-bold"
                                        },
                                        [_vm._v("Instance")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-select", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      items: _vm.instanceList,
                                      "item-text": "state",
                                      label: "Select Instance",
                                      "persistent-hint": "",
                                      hint:
                                        "Select the instance to choose a snapshot from",
                                      "return-object": "",
                                      "single-line": "",
                                      dense: "",
                                      outlined: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.getInstanceData(false, false)
                                      }
                                    },
                                    model: {
                                      value: _vm.selectedInstance,
                                      callback: function($$v) {
                                        _vm.selectedInstance = $$v
                                      },
                                      expression: "selectedInstance"
                                    }
                                  }),
                                  _vm.selectedInstance && _vm.snapshots.length
                                    ? _c("v-data-table", {
                                        attrs: {
                                          "show-select": "",
                                          headers: _vm.headers,
                                          items: _vm.snapshots,
                                          "items-per-page": -1,
                                          "custom-sort": _vm.customSort,
                                          "sort-desc": true,
                                          "item-key": "snid",
                                          "sort-by": ["snapshot_timestamp"],
                                          "single-select": "",
                                          loading: _vm.instanceFetching,
                                          "loading-text":
                                            "Fetching instance data... Please wait"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.long_id",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(_vm._s(item.long_id))
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.snapshot_timestamp",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("dateTimeToHuman")(
                                                        item.snapshot_timestamp
                                                      )
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1082528661
                                        ),
                                        model: {
                                          value: _vm.selectedSnapshot,
                                          callback: function($$v) {
                                            _vm.selectedSnapshot = $$v
                                          },
                                          expression: "selectedSnapshot"
                                        }
                                      })
                                    : _vm.selectedInstance &&
                                      !_vm.snapshots.length
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            text: "",
                                            prominent: "",
                                            type: "warning"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "The selected instance does no contain snapshots."
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              !_vm.instanceContent ||
                              (_vm.instanceContent ===
                                _vm.instanceContentOptions
                                  .CREATE_FROM_SNAPSHOT &&
                                !_vm.selectedSnapshot.length),
                            color: "secondary"
                          },
                          on: {
                            click: function($event) {
                              _vm.currentStep = 2
                            }
                          }
                        },
                        [_vm._v("continue")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: _vm.currentStep > 2 ? "secondary" : "primary",
                        complete: _vm.currentStep > 2,
                        step: "2"
                      }
                    },
                    [_vm._v("Create instances")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.validSingleInstance,
                                callback: function($$v) {
                                  _vm.validSingleInstance = $$v
                                },
                                expression: "validSingleInstance"
                              }
                            },
                            [
                              _c("h4", { staticClass: "primary--text" }, [
                                _vm._v("Name of the instance")
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  label: "Provide an instance name here",
                                  rules: [_vm.rules.nonEmpty],
                                  required: "",
                                  disabled: _vm.creatingInstance
                                },
                                model: {
                                  value: _vm.instanceName,
                                  callback: function($$v) {
                                    _vm.instanceName = $$v
                                  },
                                  expression: "instanceName"
                                }
                              }),
                              _c("ShortIdInput", {
                                attrs: {
                                  disabledEdit: _vm.creatingInstance,
                                  longId: _vm.instanceName
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.updateShortId($event.nextValue)
                                  }
                                }
                              }),
                              _c("h4", { staticClass: "mt-4 primary--text" }, [
                                _vm._v("Description of the instance")
                              ]),
                              _c("v-textarea", {
                                attrs: {
                                  label:
                                    "Provide a description of your instance",
                                  rows: "3",
                                  "auto-grow": "",
                                  rules: [_vm.rules.nonEmpty],
                                  required: "",
                                  disabled: _vm.creatingInstance
                                },
                                model: {
                                  value: _vm.instanceDescription,
                                  callback: function($$v) {
                                    _vm.instanceDescription = $$v
                                  },
                                  expression: "instanceDescription"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.creatingInstance,
                            color: "secondary",
                            text: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.currentStep = 1
                            }
                          }
                        },
                        [_vm._v("Back")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            color: "secondary",
                            loading: _vm.creatingInstance,
                            disabled:
                              _vm.validSingleInstance === false ||
                              _vm.creatingInstance
                          },
                          on: { click: _vm.addInstance }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v("Add Instance ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: _vm.currentStep > 3 ? "secondary" : "primary",
                        complete: _vm.currentStep > 3,
                        step: "3"
                      }
                    },
                    [_vm._v("Invite users")]
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { text: "", type: "info", prominent: "" } },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("invite users")
                            ]),
                            _vm._v(
                              " Do you want to invite users to this instance? "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.completeInstanceCreation(false)
                                }
                              }
                            },
                            [_vm._v("close")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.completeInstanceCreation(true)
                                }
                              }
                            },
                            [_vm._v("invite")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column align-end",
                  staticStyle: { width: "100%" }
                },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        value: _vm.$data.error,
                        color: "error",
                        icon: "warning",
                        text: ""
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.errorContent))
                        ]),
                        _c(
                          "span",
                          [
                            _vm._v(
                              " For more information on instance creation issues, check the troubleshooting documentation "
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  small: "",
                                  text: "",
                                  color: "error",
                                  href:
                                    "https://docs.nuvolos.cloud/troubleshooting/authorization-issues/cannot-create-an-instance",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("here")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }